
.favorite {
  height: 100%;
  display: flex;

  ::v-deep .el-tabs {
    height: 100%;
    box-shadow: none;
    border: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background: transparent;
    flex: 1;
    width: 1%;
  }

  ::v-deep .el-tabs__header {
    border-bottom: none;
    margin: 0;
  }

  ::v-deep .el-tabs__content {
    flex: 1;
    background: #fff;
    padding-top: 20px;
    min-height: 766px;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background: #fff;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-left: none;
  }

  ::v-deep .el-tabs__header .is-active {
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #0824ce;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-tabs__item {
    height: 52px;
    line-height: 52px;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  ::v-deep .el-tabs__item:hover {
    color: #0824ce;
    cursor: pointer;
  }
}
