
.fan-analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  .analysis-top {
    display: flex;
    padding-bottom: 34px;
    margin: 0 30px;
    .analysis-left {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-right: 5px solid #eeeeee;
      padding: 0 54px 0 24px;
      .gender-distribution {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }
        .distribution-chart {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 1%;
          justify-content: center;
          .sex-name {
            display: flex;
            justify-content: space-between;
            .name-span {
              color: #000000;
              font-size: 18px;
              line-height: 1;
            }
          }
          .chart-content {
            width: 100%;
            display: flex;
            .mab {
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              .mab-color {
                background: #ff6a6a;
                border-radius: 7px;
                height: 12px;
                margin-top: 50px;
              }
            }
            .woman {
              width: 1%;
              flex: 1;
              display: flex;
              flex-direction: column;
              text-align: center;
              .woman-color {
                width: 100%;
                height: 12px;
                background: #564ced;
                border-radius: 7px;
                margin-top: 50px;
              }
            }
          }
          .gender-icon {
            display: flex;
            justify-content: space-between;
            margin-top: 54px;
            .gender-icon-left,
            .gender-icon-right {
              display: flex;
              flex-direction: column;
              text-align: center;
              &.gender-icon-left {
                i {
                  color: #ff6a6a;
                }
              }
              &.gender-icon-right {
                i {
                  color: #564ced;
                }
              }
              i {
                font-size: 40px;
              }
              span {
                font-size: 14px;
                color: #444444;
                line-height: 1;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .analysis-right {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      .age-distribution {
        padding-bottom: 20px;
        .title {
          padding-left: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }
      }
    }
  }
  .analysis-bottom {
    display: flex;
    flex-direction: column;
    padding: 0 34px;
    .top-title {
      font-size: 16px;
      font-weight: bold;
    }
    .title-content {
      margin-top: 30px;
      .title-active {
        color: rgb(0, 0, 255);
      }
    }
    .title {
      line-height: 1;
      cursor: pointer;
    }
    .two-title {
      line-height: 1;
      display: flex;
      justify-content: space-between;
      padding: 0 14px 0 26px;
      color: #666666;
      font-size: 14px;
      margin-top: 50px;
    }
    .area-distribution {
      padding: 0 14px 0 26px;
      display: flex;
      flex-direction: column;
      .area-item-box {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .area-item {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-top: 40px;
          span {
            color: #666666;
            font-size: 14px;
            &.area-name {
              text-align: left;
              width: 50px;
            }
            &.percentage {
              width: 60px;
              text-align: right;
            }
          }
          .area-progress-box {
            display: flex;
            flex: 1;
            width: 1%;
            .area-progress-num {
              height: 15px;
              background: linear-gradient(45deg, #ffa72a 0%, #f8b95f 98%);
              border-radius: 7px;
            }
          }
        }
      }
    }
    .goodsEcharts-main {
      margin-top: 30px;
      height: auto;
      width: 100%;
      display: flex;
      .main-left {
        padding-top: 30px;
        width: 40%;
        display: flex;
        flex-direction: column;
        .top-echarts {
          align-items: center;
          font-weight: bold;
          .top-font {
            color: rgb(0, 0, 0);
            font-size: 16px;
          }
          .top-echart-main {
            width: auto;
            display: flex;
            flex: 1;
            .echart-main-size {
              width: 220px;
              height: 220px;
            }
            .echarts-content-text {
              display: flex;
              align-items: center;
              .content-title {
                display: flex;
                flex-direction: column;
              }
              .content-desc {
                margin-left: 100px;
                display: flex;
                font-size: 16px;
                flex-direction: column;
              }
            }
          }
        }
      }
      .main-right {
        padding: 0 20px;
        width: 60%;
        height: auto;
        border: 1px solid #eee;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .priceRange {
          position: relative;
          top: 35px;
        }
        ::v-deep .el-table td {
          border-bottom: none !important;
        }
        ::v-deep .el-table th.is-leaf {
          border-bottom: none !important;
        }
      }
    }
  }
  .null-data-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    
  }
}
