
.analysis-container {
  padding: 0 20px;
  .data-trend {
    margin-top: 20px;

    .data-menu-box {
      display: flex;
      justify-content: space-between;

      .data-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        text-align: center;

        .item {
          cursor: pointer;
          width: 50px;
          height: 30px;
          background: #f1f0fe;
          display: flex;
          justify-content: center;
          align-items: center;

          &.left {
            border-radius: 5px 0px 0px 5px;
          }

          &.right {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .selected {
          color: #ffffff;
          background: #564ced;
        }
      }
    }
    .table-selected {
      .table-tabs {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .top-tab {
          cursor: pointer;
        }
      }
      .active {
        color: #564ced;
      }
    }
    .sales-echarts {
      width: 100%;
      height: 430px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    ::v-deep.tooltip {
      height: 59px;
      border-radius: 2px;
      box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
      background: #fff;

      .tooltip-box {
        padding: 13px 20px 10px;
      }

      .tooltip-title {
        font-size: 12px;
        color: #999999;
      }

      .tooltip-title-right {
        float: right;
        padding-right: 10px;
        font-size: 16px;

        &.blue {
          color: #564ced;
        }
      }

      .tooltip-title-left {
        color: #333;
      }
    }
  }
}
