
.promotion-container {
  .table-box {
    padding: 0 20px;
    .college-table {
      padding: 0 20px;
    }
    .goodsList {
      display: flex;
      flex-direction: column;
      .active {
        cursor: pointer;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
.category-list {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.category-lists {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
}
.category-list {
    .category-title {
        font-weight: bold;
        color: #999;
        font-size: 14px;
        margin-right: 10px;
    }
    .category-lists {
        .category-item {
            margin: 0 10px 0px 0;
            padding: 3px 10px 3px 0;
            cursor: pointer;
            .cate-name {
                color: #666;
                &.active {
                    color: #409EFF;
                }

            }
        }
    }
}

