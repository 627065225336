
.data {
  padding: 0 30px;
  height: 100%;
  /*overflow: auto;*/
  .data-header {
    .data-title {
      font-size: 14px;
      font-weight: 400;
    }
    .data-content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      line-height: 35px;
      .data-item {
        display: flex;
        flex-direction: column;
        .g-num {
          font-size: 24px;
          font-weight: bold;
          color: #5f55f1;
        }
        .added-this-week {
          & i {
            font-size: 14px;
            color: #ffb624;
            margin: 0 14px;
          }
          & .num {
            font-size: 14px;
            font-weight: bold;
            color: #ffb624;
          }
        }
      }
      .text {
        color: #666666;
        font-size: 14px;
      }
    }
  }

  .data-trend {
    margin-top: 20px;
  }

  .data-menu {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    .item {
      line-height: 1;
      display: inline-block;
      padding: 10px 12px;
      cursor: pointer;
      background: #f1f0fe;
      border-radius: 5px 0 0 5px;
      &:hover {
        background: #564ced;
        color: #ffffff;
      }
      &:last-of-type {
        border-radius: 0 5px 5px 0;
      }
    }

    .selected {
      background: #564ced;
      color: #ffffff;
    }
  }
}
