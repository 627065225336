
.company-contain {
  background-color: #ffffff;
  height: 100%;

  .head_box {
    height: 170px;
  }

  .table-box {
    .college-table {
      padding: 0 20px;
    }

    ::v-deep .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background-color: none !important;
    }
    ::v-deep .el-table::before {
      height: none;
    }
  }

  .divide {
    padding: 10px 20px 0 20px;
  }

  .filter-box {
    padding-top: 10px;

    .box1 {
      padding: 0 20px 10px 20px;
      height: 40px;
      display: flex;

      .filter-box1 {
        span {
          margin-top: 6px;
          line-height: 32px;
          display: inline-block;
          width: 66px;
          height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
